<template>
        <div class="cont" id="p01">
            <div class="c01">
                <div class="cont-modal">
                    <div class="modal">
                        <div class="logo" style="font-size: 2em; padding-bottom: 10px; border-bottom: 2px solid #ddd">{{ codes.metaInfo.title }} <span style="position: absolute; right: 50px; top: 50px; font-size: 0.5em;">franchisee</span></div>
                        <div class="form">
                            <input 
								v-model="item.userId"
								type="text" 
								placeholder="아이디"
							>
                            <input 
								v-model="item.password"
								type="password" 
								placeholder="비밀번호"
							>
                            <div 
								v-if="error"
							class="err active"> <!-- 입력 오류시 class "active" -->
                                이메일 또는 비밀번호를 다시 확인하세요.<br> 페이링크에 등록되지 않은 아이디 또는 비밀번호를 잘못 입력하셨습니다.
                            </div>
                            <div class="ad flex-between">
                                <div class="cont-check">
                                    <input type="checkbox" id="ck01"
										:checked="idSave ? true : false"
										v-model="idSave">
                                    <label for="ck01"><span>아이디 저장</span></label>
                                </div>
                                <div class="service">
									<router-link :to="{ name: 'AuthFindId'}">아이디</router-link> / 
									<router-link :to="{ name: 'AuthFindPw'}">비밀번호 찾기</router-link>
								</div>
                            </div>
                        </div>
                        <div class="cont-btn">
                            <a ><div class="btn-type01" @click="login">로그인</div></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                Copyright © {{ codes.metaInfo.copyright }} Corp. All Rights Reserved.
            </div>
        </div>
</template>


<script>

	import { Axios } from '@/resources/axios/axios'
	
	export default {
		name: 'AuthLogin'
		,props: ['codes']
		,data: function(){
			return {
				program: {
					not_header: true
				}
				,item: {
					userId: localStorage.getItem('idSave')
					,password: ''
				}
				,error: false
				,idSave: localStorage.getItem('idSave')
			}
		}
		
		,methods: {
			login: async function(){
				let item = this.item
				try{
					let result = await Axios({
						method: 'post'
						,url: '/auth/login'
						,data: item
					})

					if(result.success){

						this.error = false
						sessionStorage.setItem('TOKEN', result.data.content.access_token)
						this.$emit('setAuth', sessionStorage.getItem('TOKEN'))
						this.$router.push('/')
						if(this.idSave){
							localStorage.setItem('idSave', this.item.userId)
						}
						
					}else{
						this.error = true
						this.$emit('setNotify', {type: 'error', message: result.message})
					}
				}catch(E){
					this.$emit('setNotify', {type: 'error', message: 'Login E'})
				}
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
		}
	}
</script>












